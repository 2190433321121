<template>
  <header>
    <a href="/" aria-label="logo">
      <svg width="223" height="32" viewBox="0 0 240 24" fill="none" xmlns="http://www.w3.org/2000/svg" style="display: block">
        <path d="M14.9436 14.8333C17.2285 14.9 18.7334 15.0667 19.4584 15.3333C19.9418 15.5111 20.1835 15.8222 20.1835 16.2667C20.1835 16.6667 19.9308 16.9333 19.4255 17.0667C18.7444 17.2444 17.2504 17.3333 14.9436 17.3333C12.5708 17.3333 11.0439 17.2111 10.3628 16.9667C9.68173 16.7444 9.34119 16.2556 9.34119 15.5H0.772827C0.772827 18.3889 1.77247 20.4889 3.77175 21.8C5.99074 23.2667 9.71468 24 14.9436 24C20.1505 24 23.8745 23.3333 26.1154 22C28.1147 20.8 29.1143 18.9444 29.1143 16.4333C29.1143 13.8333 28.2465 12.0111 26.5109 10.9667C24.5116 9.76667 20.6558 9.05556 14.9436 8.83333C12.5708 8.74444 11.0768 8.63333 10.4617 8.5C9.97832 8.36667 9.73665 8.11111 9.73665 7.73333C9.73665 7.33333 9.97832 7.06667 10.4617 6.93333C11.1427 6.75555 12.6367 6.66667 14.9436 6.66667C17.0527 6.66667 18.4478 6.78889 19.1289 7.03333C19.8319 7.25555 20.1835 7.74444 20.1835 8.5H28.7518C28.7518 5.58889 27.8071 3.48889 25.9177 2.2C23.8085 0.733333 20.1505 0 14.9436 0C9.71468 0 5.99074 0.633333 3.77175 1.9C1.77247 3.05556 0.772827 4.87778 0.772827 7.36667C0.772827 9.96667 1.62966 11.7667 3.34333 12.7667C5.34262 13.9667 9.20937 14.6556 14.9436 14.8333Z" fill="#EEF0F1"/>
        <path d="M45.2676 24C49.9033 24 53.4405 22.9444 55.8792 20.8333C58.2519 18.7667 59.4383 15.8222 59.4383 12V0.333332H50.87V12C50.87 15.1111 49.0025 16.6667 45.2676 16.6667C41.5326 16.6667 39.6652 15.1111 39.6652 12V0.333332H31.0968V12C31.0968 15.8222 32.2832 18.7667 34.656 20.8333C37.0947 22.9444 40.6319 24 45.2676 24Z" fill="#EEF0F1"/>
        <path d="M70.7976 7.66667H76.4659C77.9599 7.66667 78.7068 8.22222 78.7068 9.33333C78.7068 10.4444 77.9599 11 76.4659 11H70.7976V7.66667ZM70.7976 18.3333H76.1693C79.7944 18.3333 82.5626 17.5333 84.474 15.9333C86.3415 14.4 87.2752 12.2 87.2752 9.33333C87.2752 6.46667 86.3415 4.26667 84.474 2.73333C82.5626 1.13333 79.7944 0.333332 76.1693 0.333332H62.2292V23.6667H70.7976V18.3333Z" fill="#EEF0F1"/>
        <path d="M89.5846 0.333332V23.6667H113.972V17H98.153V15H113.642V9H98.153V7H113.972V0.333332H89.5846Z" fill="#EEF0F1"/>
        <path d="M125.026 7.66667H132.012C133.506 7.66667 134.253 8.22222 134.253 9.33333C134.253 10.4444 133.506 11 132.012 11H125.026V7.66667ZM125.026 18.3333H130.397L133.924 23.6667H142.822L138.406 17C141.35 15.5111 142.822 12.9556 142.822 9.33333C142.822 6.46667 141.888 4.26667 140.02 2.73333C138.109 1.13333 135.341 0.333332 131.716 0.333332H116.457V23.6667H125.026V18.3333Z" fill="#EEF0F1"/>
        <path d="M145.293 0.333332V23.6667H153.862V17.3333H168.691V10H153.862V7.66667H169.021V0.333332H145.293Z" fill="#EEF0F1"/>
        <path d="M171.329 23.6667H179.897V0.333332H171.329V23.6667Z" fill="#EEF0F1"/>
        <path d="M202.978 0.333332V12.8333L193.091 0.333332H183.205V23.6667H191.773V11.1667L201.66 23.6667H211.546V0.333332H202.978Z" fill="#EEF0F1"/>
        <path d="M214.84 0.333332V23.6667H239.227V17H223.409V15H238.898V9H223.409V7H239.227V0.333332H214.84Z" fill="#EEF0F1"/>
      </svg>
    </a>
    <div style="flex-grow: 1"></div>
    <button
      class="button button-outline"
      @click="signup"
    >
      Sign up
    </button>
    <button
      class="button button-gradient"
      @click="login"
    >
      Sign in
    </button>
  </header>
</template>
<script>
import {platformURL} from "@/utils/constants";

export default {
  name: "TheHeader",
  data() {
    return {

    }
  },
  methods: {
    login() {
      window.location.href = `${platformURL}/login`
    },
    signup() {
      window.location.href = `${platformURL}/login?signup=1`
    },
    talk() {
      // this.$router.push('/call')
      window.open('https://docs.google.com/forms/d/1ooHq3H_U2JVietPbAs9wWMGV92mOs_VTGpM8JEVo0-U/viewform?edit_requested=true', '_blank')
    }
  }
}
</script>

<style scoped lang="scss">
 header {
   position: sticky;
   top: 0;
   z-index: 100;
   width: 100%;
   background: #FFFFFF05;
   padding: 16px 80px;
   backdrop-filter: blur(32px);
   display: flex;
   align-items: center;
   gap: 8px;
 }

 @media screen and (max-width: 768px) {
   header {
     padding-left: 40px;
     padding-right: 40px;
   }
 }

 @media screen and (max-width: 480px) {
   header {
     padding-left: 24px;
     padding-right: 24px;

     svg {
       width: 120px;
     }

     button {
       min-width: unset;
       padding: 4px 8px;
       font-size: 12px;
       line-height: 20px;
     }
   }
 }
</style>
