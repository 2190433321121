<template>
  <TheHeader
    v-if="!hideHeader"
  />
  <router-view/>
</template>

<script setup>
import TheHeader from "@/components/TheHeader.vue";
import { computed } from 'vue';
import { useRoute } from 'vue-router';

const hideHeader = computed(() => {
  const route = useRoute()
  return !!route.query?.hideHeader || !!route.meta?.hideHeader
})
</script>
